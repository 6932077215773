




























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/ProgrammaticVoucherController'
import routeController from '@/app/ui/controllers/RouteController'
import { Utils } from '@/app/infrastructures/misc'
import Badge from '@/app/ui/components/Badge/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import { ProgrammaticVoucher } from '@/domain/entities/ProgrammaticVoucher'
import * as constantData from '@/app/infrastructures/misc/Constants/programaticVoucher'

@Component({
  components: {
    Badge,
    Button,
    Modal,
    EditIcon,
    LoadingOverlay,
  },
})
export default class ProgrammaticVoucherDetail extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  confirmationModal = false
  successModal = false
  isEmptyPointPurposeAndExpire = false

  created(): void {
    controller.getTriggers()
    controller.getProgrammaticVoucherDetail(this.$route.params.id)
  }

  @Watch('voucherDetail')
  onVoucherDetailChanged(val: {
    voucherTarget: string
    pointPurpose: string
    pointExpiry: number
  }): void {
    if (
      <constantData.UserType>val.voucherTarget ===
      constantData.UserType.SPECIFIC_ORIGIN
    ) {
      routeController.getCityList()
    }

    if (
      val.pointPurpose === '' &&
      (!val.pointExpiry || val.pointExpiry === -1)
    ) {
      this.isEmptyPointPurposeAndExpire = true
    }
  }

  @Watch('controller.isSuccessCancelVoucher')
  onSuccessCancelVoucher(isSuccess: boolean): void {
    if (isSuccess) {
      this.successModal = true
    }
  }

  get isCancelled(): boolean {
    return (
      <constantData.StatusType>this.voucherDetail.status ===
      constantData.StatusType.CANCELLED
    )
  }

  get voucherDetail(): ProgrammaticVoucher {
    return controller.voucherDetail
  }

  get isOnScheduleTrigger(): boolean {
    return (
      <constantData.TriggerType>this.voucherDetail.voucherTrigger ===
      constantData.TriggerType.SCHEDULE
    )
  }

  get isSelectedUserTarget(): boolean {
    return (
      <constantData.UserType>this.voucherDetail.voucherTarget ===
      constantData.UserType.SELECTED
    )
  }

  get isMembershipTarget(): boolean {
    return (
      <constantData.UserType>this.voucherDetail.voucherTarget ===
      constantData.UserType.MEMBERSHIP_LEVEL
    )
  }

  get isOSTarget(): boolean {
    return (
      <constantData.UserType>this.voucherDetail.voucherTarget ===
      constantData.UserType.SPECIFIC_OS
    )
  }

  get isOriginTarget(): boolean {
    return (
      <constantData.UserType>this.voucherDetail.voucherTarget ===
      constantData.UserType.SPECIFIC_ORIGIN
    )
  }

  get userType(): string | undefined {
    return constantData.TARGET_USERS.find(
      item =>
        item.value === <constantData.UserType>this.voucherDetail.voucherTarget
    )?.label
  }

  get triggerType(): string | undefined {
    return controller.triggerData.find(
      item => item.value === this.voucherDetail.voucherTrigger
    )?.label
  }

  get purposeParent(): string | undefined {
    return constantData.PURPOSE_OPTIONS.find(
      item =>
        item.value ===
        <constantData.PurposeType>this.voucherDetail.voucherPurposeParent
    )?.label
  }

  get purposeChild(): string | undefined {
    return constantData.TERM_COND_OPTIONS.find(
      item =>
        item.value ===
        <constantData.TermConditionType>this.voucherDetail.voucherPurposeChild
    )?.label
  }

  get selectedUserFile(): string {
    return this.voucherDetail.selectedUserUrl?.split('/').slice(-1)[0] || '-'
  }

  get osInfo(): string {
    return `${this.voucherDetail.os} Apps ${this.voucherDetail.osValidation} v${this.voucherDetail.osValidationVersion}`
  }

  get isRedeemToPoint(): boolean {
    return (
      this.voucherDetail.voucherPurposeChild ===
      <string>constantData.TermConditionType.REDEEM_TO_POINT
    )
  }

  get isDiscByAmount(): boolean {
    return (
      this.voucherDetail.voucherPurposeChild ===
      <string>constantData.TermConditionType.DISCOUNT_BY_AMOUNT
    )
  }

  get isDiscByPercent(): boolean {
    return (
      this.voucherDetail.voucherPurposeChild ===
      <string>constantData.TermConditionType.DISCOUNT_BY_PERCENTAGE
    )
  }

  get isCashback(): boolean {
    return (
      this.voucherDetail.voucherPurposeChild ===
      <string>constantData.TermConditionType.CASHBACK
    )
  }

  get listOfCities(): string {
    return routeController.cityData
      .filter(item =>
        this.voucherDetail.routeIds?.includes(item.value.toString())
      )
      .map(item => item.label)
      .join(', ')
  }

  get statusBadgeType(): string {
    const status = <constantData.StatusType>this.voucherDetail?.status
    if (status === constantData.StatusType.EXPIRED) {
      return 'error'
    } else if (status === constantData.StatusType.CANCELLED) {
      return 'cancelled'
    } else if (
      status === constantData.StatusType.ACTIVE ||
      status === constantData.StatusType.PUBLISHED
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get minimumTransaction(): string | number {
    if (this.isDiscByAmount) {
      return this.formatCurrency(this.voucherDetail.discountMinimum || 0)
    } else if (this.isDiscByPercent) {
      return this.formatCurrency(
        this.voucherDetail.discountPercentageMinimum || 0
      )
    } else if (this.isCashback) {
      return this.formatCurrency(
        this.voucherDetail.cashbackPercentageMinimum || 0
      )
    } else {
      return '-'
    }
  }

  get maximumTransaction(): string | number {
    if (this.isDiscByPercent) {
      return this.formatCurrency(
        this.voucherDetail.discountPercentageMaximum || 0
      )
    } else if (this.isCashback) {
      return this.formatCurrency(
        this.voucherDetail.cashbackPercentageMaximum || 0
      )
    } else {
      return '-'
    }
  }

  get isPendingOrActive(): boolean {
    return (
      this.voucherDetail.status === 'ACTIVE' ||
      this.voucherDetail.status === 'PENDING'
    )
  }

  private formatCurrency(number: number): string | number {
    return `Rp${Utils.currencyDigit(number || 0)}`
  }

  private cancelVoucher(): void {
    controller.deleteVoucher({ voucherId: this.$route.params.id })
    this.confirmationModal = false
  }

  private onCloseSuccessModal(): void {
    controller.getProgrammaticVoucherDetail(this.$route.params.id)
    this.successModal = false
  }

  private formatDate(
    date: string | undefined | null,
    format = 'DD MMMM YYYY, HH:mm Z'
  ): string {
    return Utils.formatTimeZone(Utils.formatDate(date || '-', format))
  }
}
